<template>
  <v-skeleton-loader
    v-bind="$attrs"
    type="article"
  >
    <slot />
  </v-skeleton-loader>
</template>
<script>
export default {
  props: {
    height: {
      type: Number
    }
  },
  name: 'ItemLoaderNote'
}
</script>